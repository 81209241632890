<template>
    <el-dialog width="600" v-bind:title="$t('Controller')+' '+$t('Settings')" :visible="dialogControllerVisible" @close="dialogControllerOnClose()">
        <el-form ref="dataForm" :rules="rules" :model="updateQuery" size="small" style="margin-top: 10px;">
            <el-row :gutter="10">
                <el-col :xs="22" :sm="22" :md="10" :lg="10" :xl="10" v-if="serviceList.length>0">
                    <el-form-item prop="serviceId" v-bind:label="$t('Operator')" label-width="150px">
                        <el-select id="serviceId" v-model="serviceId" size="small" class="filter-item" style="width: 200px">
                            <el-option
                                  v-for="item in this.serviceList"
                                  :label="item.name"
                                  :key="item.id"
                                  :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11">
                    <el-form-item prop="stationId" v-bind:label="$t('ChargingStation')" label-width="150px">
                        <el-select id="stationId" v-model="updateQuery.stationId" size="small" class="filter-item" style="width: 200px">
                            <el-option
                                  v-for="item in this.chargingStations"
                                  :label="item.name"
                                  :key="item.id"
                                  :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="10">
                <el-col :xs="22" :sm="22" :md="10" :lg="10" :xl="10">
                    <el-form-item prop="controllerName" v-bind:label="$t('Name')" label-width="150px">
                        <el-input id="controllerName" size="small" v-model="updateQuery.controllerName" :placeholder="$t('Input')+' '+$t('Name')" class="filter-item"
                            clearable style="width: 200px" />
                    </el-form-item>
                </el-col>
                <el-col :xs="22" :sm="22" :md="10" :lg="10" :xl="10">
                    <el-form-item prop="slaveId" label="Slave UnitID" label-width="150px">
                        <el-input id="slaveId" size="small" v-model="updateQuery.slaveId" :placeholder="$t('Input')+' slaveId'" class="filter-item"
                            clearable style="width: 200px" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="10">
                <el-col :xs="22" :sm="22" :md="10" :lg="10" :xl="10">
                    <el-form-item prop="ip" label="IP" label-width="150px">
                        <el-input id="ip" size="small" v-model="updateQuery.ip" :placeholder="$t('Input')+' IP'" class="filter-item"
                            clearable style="width: 200px" />
                    </el-form-item>
                </el-col>
                <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11">
                    <el-form-item prop="port" label="PORT" label-width="150px">
                        <el-input id="port" size="small" v-model="updateQuery.port" :placeholder="$t('Input')+' PORT'" class="filter-item"
                            clearable style="width: 200px" />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <el-button style="margin-bottom: 0px; margin-top: 10px;" size="median" type="primary" @click="updateController()">{{$t('Save')}}</el-button>
    </el-dialog>
</template>

<script>
import { mixins } from '@/views/common/mixins.js'
import {apiUrl} from "@/utils/main";

export default {
    name: 'ControllerSetting',
    mixins: [mixins],
    props: { //一個元件需要明確地宣告它所接受的props，這樣Vue 才能知道外部傳入的哪些是props
        controllerData: {
            require: false,
            default: undefined,
            type: Object
        },
        serviceList: {
            require: false,
            default: undefined,
            type: Array
        },
        dialogControllerVisible: {
            require: true,
            default: undefined,
            type: Boolean
        }
    },
    data() {
        return {
            isFirstModify: false,
            serviceId: undefined,
            chargingStations: this.$store.state.chargingStations,
            updateQuery: {
                id: undefined,
                controllerName: undefined,
                stationId: undefined,
                ip: undefined,
                port: undefined,
                slaveId: undefined
            },
            rules: {
                controllerName: [{ required: true, message: '(必填)', trigger: 'blur' }],
                stationId: [{ required: true, message: '(必填)', trigger: 'blur' }],
                ip: [{ required: true, message: '(必填)', trigger: 'blur' }],
                port: [{ required: true, message: '(必填)', trigger: 'blur' }],
                slaveId: [{ required: true, message: '(必填)', trigger: 'blur' }],
            }
        };
    },
    watch: {
        controllerData(val) {
            if (val) {
                this.isFirstModify = true
                this.serviceId = val.serviceInfo.id
                this.updateQuery.id = val.id
                this.updateQuery.controllerName = val.controllerName
                this.updateQuery.stationId = val.stationId
                this.updateQuery.ip = val.ip
                this.updateQuery.port = val.port
                this.updateQuery.slaveId = val.slaveId
            }
        },
        'serviceId': function () { //首次進入midify頁面isFirstModify為true,保留stationId
            this.chargingStations = (this.$store.state.chargingStations || []).filter(item => item.service_id == this.serviceId)
            if (!this.isFirstModify)
                this.updateQuery.stationId = undefined
            this.isFirstModify = false
        },
    },
    methods: {
        dialogControllerOnClose() {
            this.resetUpdateQuery()
            this.$refs.dataForm.resetFields() // 表單校驗結果重置
            this.$emit('close') // 觸發父層 close
        },
        updateController() {
            this.$refs['dataForm'].validate((valid) => {
                if (valid) {
                    this.axios.put('/api/v1/meter', this.updateQuery).then(() => {
                        this.$message({
                            showClose: true,
                            message: this.$t('SuccessEdited'),
                            type: 'success'
                        })
                        this.dialogControllerOnClose()
                    }).catch(err => {
                        this.$message({
                            message: err.data,
                            type: 'error'
                        })
                    })
                } else {
                    this.$message({
                        message: '輸入欄位格式不正確',
                        type: 'error'
                    })
                }
            })
        },
        resetUpdateQuery() {
            this.isFirstModify = false
            this.serviceId = undefined
            this.updateQuery = {
                id: undefined,
                controllerName: undefined,
                stationId: undefined,
                ip: undefined,
                port: undefined,
                slaveId: undefined
            }
        }
    }
}
</script>
