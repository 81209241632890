<template>
    <el-dialog width="600" v-bind:title="$t('Device')+' '+$t('Settings')" :visible="dialogDeviceVisible" @close="dialogDeviceOnClose()">
        <el-form ref="dataForm" :rules="rules" :model="updateQuery" size="small">
            <el-row :gutter="10">
                <el-col :xs="22" :sm="22" :md="10" :lg="10" :xl="10">
                    <el-form-item prop="name" v-bind:label="$t('Name')">
                        <el-input id="name" size="small" v-model="updateQuery.name" :placeholder="$t('Input')+' '+$t('Name')" class="filter-item" clearable/>
                    </el-form-item>
                </el-col>
                <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11">
                    <el-form-item prop="orgPath" v-bind:label="$t('Path')">
                        <el-input id="orgPath" size="small" v-model="updateQuery.orgPath" placeholder="e.g. CHT/TL/大安站" class="filter-item" clearable :disabled="this.isModify"/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="10">
                <el-col :xs="22" :sm="22" :md="10" :lg="10" :xl="10">
                    <el-form-item prop="ocppScType" v-bind:label="$t('OcppScType')">
                        <el-select id="ocppScType" v-model="updateQuery.ocppScType" size="small" class="filter-item">
                            <el-option v-bind:label="$t('NotControl')" value="" />
                            <el-option v-bind:label="$t('Max')+$t('Current')+$t('Control')" value="A" />
                            <el-option v-bind:label="$t('Max')+$t('Power')+$t('Control')" value="W" />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11">
                    <el-form-item prop="ocppScMaxValue" v-bind:label="$t('Max')+$t('Current')+'/'+$t('Power')">
                        <el-input id="ocppScMaxValue" size="small" v-model="updateQuery.ocppScMaxValue" :placeholder="$t('Input')+' '+$t('Max')+$t('CurrentA')+'/'+$t('PowerW')" class="filter-item" clearable/>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <el-button style="margin-bottom: 0px; margin-top: 10px;" size="median" type="primary" @click="updateDevice()">{{$t('Save')}}</el-button>
    </el-dialog>
</template>

<script>
import { mixins } from '@/views/common/mixins.js'
import {apiUrl} from "@/utils/main";

export default {
    name: 'DeviceSetting',
    mixins: [mixins],
    props: { //一個元件需要明確地宣告它所接受的props，這樣Vue 才能知道外部傳入的哪些是props
        deviceData: {
            require: false,
            default: undefined,
            type: Object
        },
        listData: {
            require: false,
            default: undefined,
            type: Object
        },
        dialogDeviceVisible: {
            require: true,
            default: undefined,
            type: Boolean
        }
    },
    data() {
        return {
            isModify: false,
            stationId: undefined,
            updateQuery: {
                controllerId: undefined,
                id: undefined,
                name: undefined,
                orgPath: undefined,
                ocppScMaxValue: undefined,
                ocppScType: undefined
            },
            rules: {
                name: [{ required: true, message: '(必填)', trigger: 'blur' }],
                orgPath: [{ required: true, message: '(必填)', trigger: 'blur' }],
                //ocppScMaxValue: [{ required: true, message: '(必填)', trigger: 'blur' }],
                //ocppScType: [{ required: true, message: '(必填)', trigger: 'blur' }],
            }
        };
    },
    watch: {
        deviceData(val) { // update device
            if (val) {
                this.isModify = true
                this.updateQuery.controllerId = val.controllerId
                this.updateQuery.id = val.id
                this.updateQuery.name = val.name
                this.updateQuery.orgPath = val.orgPath
                this.updateQuery.ocppScMaxValue = val.ocppScMaxValue
                this.updateQuery.ocppScType = val.ocppScType
            }
        },
        listData(val) { // create device
            if (val) {
                this.updateQuery.controllerId = val.controllerId
                this.stationId = val.stationId
            }
        }
    },
    methods: {
        dialogDeviceOnClose() {
            this.resetUpdateQuery()
            this.$refs.dataForm.resetFields() // 表單校驗結果重置
            this.$emit('close') // 觸發父層 close
        },
        updateDevice() {
            this.$refs['dataForm'].validate((valid) => {
                if (valid) {
                    this.axios.put('/api/v1/device', this.updateQuery).then(() => {
                        this.$message({
                            showClose: true,
                            message: this.$t('SuccessEdited'),
                            type: 'success'
                        })
                        this.dialogDeviceOnClose()
                    }).catch(err => {
                        this.$message({
                            message: this.$t('FailEdited')+err.data,
                            type: 'error'
                        })
                    })
                } else {
                    this.$message({
                        message: '輸入欄位格式不正確',
                        type: 'error'
                    })
                }
            })
        },
        resetUpdateQuery() {
            this.isModify = false
            this.updateQuery = {
                controllerId: undefined,
                id: undefined,
                name: undefined,
                orgPath: undefined,
                ocppScMaxValue: undefined,
                ocppScType: undefined,
            }
        }
    }
}
</script>
<style>
/* Medium devices (tablets, 768px and up) */
@media (max-width: 767.98px) {
    .el-form-item__content {
        text-align: left;
    }
}
@media (min-width: 768px) {
    .el-input {
        width: 200px;
    }
    .el-select {
        width: 200px;
    }
    .el-form-item__content {
        text-align: left;
        margin-left: 110px;
    }
    .el-form-item__label {
        width: 110px;
    }
 }
</style>