<template>
  <div style="margin-top:20px">
  <el-col :xs="24" :sm="24" :md="10" :lg="10" :xl="7" style="padding: 7px;">
  <el-card class="box-card" shadow="never">
    <div slot="header" class="clearfix">
      <span class="role-span">{{$t('Device')+' '+$t('List')}}</span>
      <el-button style="float: left" size="mini" type="primary" icon="el-icon-plus" @click="handleDeviceAdd">
        {{$t('Add')+$t('Device')}}
      </el-button>
    </div>
    <el-table
      :header-cell-style="{color:'#20638C'}"
      v-loading="listLoading"
      :data="list" style="width: 100%;"
      :cell-style="{padding: '0', height: '40px'}"
      @sort-change="tableSortChange"
     >
      <el-table-column align="center" prop="id" v-bind:label="$t('Number')" />
      <el-table-column align="center" prop="name" v-bind:label="$t('Name')" min-width="100%" />
      <!--<el-table-column align="center" prop="orgPath" v-bind:label="$t('Path')" min-width="180%" />-->
      <el-table-column align="center" v-bind:label="$t('ChargingPoint')+$t('List')" >
        <template slot-scope="scope">
            <el-button type="success" size="mini" icon="el-icon-search" @click="setDeviceStation(scope.row)"/>
        </template>
      </el-table-column>
      <el-table-column align="center" :label="$t('Operate')" width="130px" >
        <template slot-scope="scope">
            <el-button type="success" :title="$t('Edit')" size="mini" icon="el-icon-setting" @click="showDeviceSetting(scope.row)"/>
            <el-button type="danger" :title="$t('Delete')" size="mini" icon="el-icon-delete" @click="handleDeviceDelete(scope.$index, scope.row)" />
        </template>
      </el-table-column>
    </el-table>
    <!--分頁組件-->
    <pagination v-show="total>0" :total="total" :current.sync="paginationSelectedPage" :size.sync="listQuery.size" @pagination="getList" />
    <DeviceSetting :dialogDeviceVisible="dialogDeviceSettingVisible" :deviceData="deviceData" :listData="listData" @close="dialogDeviceSettingOnClose()" />
    <DeviceStationSetting :dialogDeviceStationVisible="dialogDeviceStationSettingVisible" :deviceData="deviceData" :treeData="treeData" @close="dialogDeviceStationSettingOnClose()" />
  </el-card>
  </el-col>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { mixins } from '@/views/common/mixins.js'
import DeviceSetting from './DeviceSetting.vue'
import DeviceStationSetting from './DeviceStationSetting.vue'

export default {
  name: 'Device',
  components: {
    Pagination,
    DeviceSetting,
    DeviceStationSetting
  },
  mixins: [mixins],
  props: { //一個元件需要明確地宣告它所接受的props，這樣Vue 才能知道外部傳入的哪些是props
      serviceId: {
          require: false,
          default: undefined,
          type: Number
      },
      controllerId: {
          require: false,
          default: undefined,
          type: Number
      },
      stationId: {
          require: false,
          default: undefined,
          type: Number
      },
      returnData: {
          require: false,
          default: undefined,
          type: Number
      }
  },
  data() {
    return {
      dialogWidth: 0,
      total: 0,
      list: [],
      listLoading: false,
      listData: undefined,
      listQuery: {
        serviceId: undefined,
        controllerId: undefined,
        page: 0, // jpa pageIndex起始從0開始，但Pagination從1開始，透過watch paginationSelectedPage同步
        size: 15,
        sort: 'id,Desc'
      },
      paginationSelectedPage: 1, // 需配合Pagination元件與listQuery使用
      deviceData: undefined, // 設備資訊
      allTreeData: undefined,
      treeData: undefined,
      dialogDeviceSettingVisible: false,
      dialogDeviceStationSettingVisible: false,
    };
  },
  watch: {
    serviceId(val) {
        if (val) {
            this.listQuery.serviceId = val
            this.getStationPointTree(val)
        }
    },
    controllerId(val) {
        if (val)
            this.listQuery.controllerId = val
    },
    returnData(val) {
        this.listQuery.page = 0
        this.resetPage(this.getList)
    },
    'listQuery.controllerId': function() {
      this.listQuery.page = 0
      this.resetPage(this.getList)
    },
    paginationSelectedPage: {
      handler: function(newValue) {
        this.listQuery.page = newValue - 1
      }
    }
  },
  async created() {
    this.dialogWidth = this.setDialogWidth()
    this.resetPage(this.getList)
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.dialogWidth = this.setDialogWidth()
      })()
    }
  },
  methods: {
    getList() {
      if (this.listQuery.controllerId!=undefined) {
          this.listLoading = true
          this.axios.get('/api/v1/device', {params: this.listQuery}).then(res=> {
            this.list = res.data.content
            this.total = res.data.totalElements
            this.listLoading = false
          })
      }
    },
    getStationPointTree(serviceId) {
        this.axios.get('/api/v1/common/tree/station/enabled', { params:  { serviceId: serviceId}}).then(res => {
            //this.treeData = (res.data || []).filter((item) => item.id === this.stationId+"");
           this.allTreeData = res.data
        })
    },
    handleDeviceAdd() {
      this.dialogDeviceSettingVisible = true
      this.listData = this.listQuery
    },
    showDeviceSetting(row) {
      this.dialogDeviceSettingVisible = true
      this.deviceData = row
    },
    setDeviceStation(row) {
      this.dialogDeviceStationSettingVisible = true
      this.deviceData = row
      this.treeData = (this.allTreeData || []).filter((item) => item.id === this.stationId+"");
    },
    handleDeviceDelete(index, row) {
      const confirmText = ['確定刪除設備 - ' + row.name + ' ?','(請確認此設備已無納管任何監控點)']
        const newDatas = []
        const h = this.$createElement
        for (const i in confirmText) {
          newDatas.push(h('p', null, confirmText[i]))
      }
      this.$confirm('提示', {
        message: h('div', null, newDatas),
        //message: '確定刪除設備 - ' + row.name + ' ?',
        confirmButtonText: '確認',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delDevice(index, row.id)
      }).catch(()=> {
        console.log('cancel delete tag')
      })
    },
    async delDevice(index, id) {
      this.axios.delete('/api/v1/device/' + id).then(()=> {
        this.$delete(this.list, index);
        this.$message({showClose: true,message: '刪除成功',type: 'success'})
      }).catch(err => {
        this.$message({message: '刪除失敗 '+err.data,type: 'error'})
      })
    },
    dialogDeviceSettingOnClose() {
      this.dialogDeviceSettingVisible = false
      this.deviceData = undefined
      this.resetPage(this.getList)
    },
    dialogDeviceStationSettingOnClose() {
      this.dialogDeviceStationSettingVisible = false
      this.listData = undefined
      this.deviceData = undefined
      this.resetPage(this.getList)
    },
  }
}
</script>
