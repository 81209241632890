<template>
    <el-dialog width="420px" v-bind:title="$t('ChargingPoint')+$t('Settings')" :visible="dialogDeviceStationVisible" @close="dialogDeviceStationOnClose()">
        <el-form ref="dataForm" :model="updateQuery" size="small" style="margin-top: -20px;">
            <el-row :gutter="20">
                <el-col :xs="22" :sm="22" :md="22" :lg="22" :xl="22">
                    <el-row :gutter="20" v-loading="treeLoading" style="border: 0px solid #f6f6f6; min-height: 240px; max-height: 400px; padding-left: 20px; padding-right: 20px; overflow-y: auto;">
                        <template>
                            <el-tree
                                ref="treeRef"
                                style="max-width: 600px"
                                :data="treeData"
                                show-checkbox
                                default-expand-all
                                node-key="id"
                                :default-checked-keys=checkedTreeItems
                                :props="defaultProps"/>
                        </template>
                    </el-row>
                </el-col>
            </el-row>
        </el-form>
        <el-button style="margin-bottom: 0px; margin-top: 10px;" size="median" type="primary" @click="updateDevicePoint()">{{$t('Save')}}</el-button>
    </el-dialog>
</template>

<script>
import { mixins } from '@/views/common/mixins.js'
import {apiUrl} from "@/utils/main";

export default {
    name: 'DeviceStationSetting',
    mixins: [mixins],
    props: { //一個元件需要明確地宣告它所接受的props，這樣Vue 才能知道外部傳入的哪些是props
        deviceData: {
            require: false,
            default: undefined,
            type: Object
        },
        treeData: {
            require: false,
            default: undefined,
            type: Array
        },
        dialogDeviceStationVisible: {
            require: true,
            default: undefined,
            type: Boolean
        }
    },
    data() {
        return {
            updateQuery: {
                id: undefined,
                points: []
            },
            defaultProps : {
                children: 'children',
                label: 'label',
            },
            treeLoading: false,
            //treeData: [],
            checkedTreeItems: []
        };
    },
    watch: {
        deviceData(val) {
            if (val) {
                this.updateQuery.id = val.id
                this.treeLoading = false
                this.loadItems();
            }
        },
    },
    methods: {
        loadItems() {
            this.treeLoading = true
            this.axios.get("/api/v1/device/point/"+this.updateQuery.id).then(res => {
                this.checkedTreeItems = res.data
                this.treeLoading = false
            })
        },
        dialogDeviceStationOnClose() {
            this.resetUpdateQuery()
            this.$refs.dataForm.resetFields() // 表單校驗結果重置
            this.$emit('close') // 觸發父層 close
        },
        updateDevicePoint() {
            console.log("id:"+this.updateQuery.id)
            this.updateQuery.points = this.$refs.treeRef.getCheckedKeys(true)
            console.log("points:"+this.updateQuery.points)
            this.$refs['dataForm'].validate((valid) => {
                if (valid) {
                    this.axios.put('/api/v1/device/point/'+this.updateQuery.id, this.updateQuery.points).then(() => {
                        this.$message({
                            showClose: true,
                            message: this.$t('SuccessEdited'),
                            type: 'success'
                        })
                        this.dialogDeviceStationOnClose()
                    }).catch(err => {
                        this.$message({
                            message: err.data,
                            type: 'error'
                        })
                    })
                } else {
                    this.$message({
                        message: '輸入欄位格式不正確',
                        type: 'error'
                    })
                }
            })
        },
        resetUpdateQuery() {
            this.updateQuery = {
                id: undefined,
                points: []
            }
            this.$refs.treeRef.setCheckedKeys([])
        }
    }
}
</script>
